type FilterIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function FilterIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: FilterIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 4L10 4M14 4L21 4M3 12H12M16 12H21M3 20H8M12 20H21M10 1L10 7M16 9V15M8 17L8 23"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
