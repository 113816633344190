export function ComputerIcon(props) {
  const { isBigVersion } = props;
  return (
    <svg
      width={isBigVersion ? "48" : "22"}
      height={isBigVersion ? "48" : "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 17.1037V19.323L15.4 20.4327V21.5423H6.6L6.59603 20.4367L8.8 19.323V17.1037H1.09098C0.488455 17.1037 0 16.6056 0 15.9858V1.57693C0 0.959514 0.500841 0.458984 1.09098 0.458984H20.909C21.5116 0.458984 22 0.957128 22 1.57693V15.9858C22 16.6032 21.4992 17.1037 20.909 17.1037H13.2ZM2.2 2.67828V12.6651H19.8V2.67828H2.2Z"
        fill={isBigVersion ? "#AE70FF" : "#E0E0E0"}
      />
    </svg>
  );
}
