import { MediaItem } from "../../../../../utils/Types";
import { useEffect, useState } from "react";
import { MultiPreviewIcon } from "../../../../icons/MultiPreview";

type MultiSelectionProps = {
  selected: MediaItem[];
};

export function MultiSelection({ selected = [] }: MultiSelectionProps) {
  const [current, setCurrent] = useState<MediaItem[]>(selected);
  useEffect(() => {
    setCurrent(current);
  }, [current]);

  const [actionGroups, setActionGroups] = useState([
    {
      items: [
        {
          type: "action",
          name: "Edit",
          highlighted: true,
        },
      ],
    },
    {
      items: [
        {
          type: "action",
          name: "Preview",
          highlighted: false,
        },
        {
          type: "action",
          name: "Move to...",
          highlighted: false,
        },
      ],
    },
    {
      items: [
        {
          type: "action",
          name: "Copy",
          highlighted: false,
        },
        {
          type: "action",
          name: "Cut",
          highlighted: false,
        },
        {
          type: "action",
          name: "Download",
          highlighted: false,
        },
      ],
    },
    {
      items: [
        {
          type: "action",
          name: "Delete",
          highlighted: false,
        },
      ],
    },
  ]);

  const itemClass = (item) => {
    let className = "cursor-pointer w-full";
    if (item.highlighted) {
      className += " font-semibold";
    }
    return className;
  };

  return (
    <div className="w-full">
      <div className="w-full mb-4 text-center">
        <MultiPreviewIcon width={150} height={150} />
      </div>
      <div className="w-full mb-6  text-center">
        {selected.length} items selected
      </div>
      {actionGroups.length > 0 ? (
        <div className="w-full">
          {actionGroups.map((group, i) => (
            <div
              key={"group-" + i}
              className="border-t border-t-[#A0A0A0] py-2"
            >
              {group.items.map((item, j) => (
                <div key={"group-" + j} className={itemClass(item)}>
                  {item.name}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
