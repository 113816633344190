export function FontOutlineColor() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.73271 1C7.46203 1 7.21863 1.16479 7.1181 1.41611L2.04779 14.0919C1.91201 14.4313 2.07711 14.8166 2.41655 14.9523C2.75598 15.0881 3.14122 14.923 3.27699 14.5836L4.67544 11.0875C4.75137 10.8976 4.93523 10.7732 5.13968 10.7732H10.3257C10.5302 10.7732 10.714 10.8976 10.79 11.0875L12.1884 14.5836C12.3242 14.923 12.7094 15.0881 13.0489 14.9523C13.3883 14.8166 13.5534 14.4313 13.4176 14.0919L8.34731 1.41611C8.24679 1.16479 8.00338 1 7.73271 1ZM6.18963 1.04472C6.44202 0.413743 7.05313 0 7.73271 0C8.41229 0 9.0234 0.413743 9.27579 1.04472L14.3461 13.7205C14.687 14.5727 14.2725 15.5399 13.4203 15.8808C12.568 16.2217 11.6008 15.8072 11.2599 14.955L9.98722 11.7732H5.4782L4.20547 14.955C3.86458 15.8072 2.89738 16.2217 2.04516 15.8808C1.19294 15.5399 0.778422 14.5727 1.11931 13.7205L6.18963 1.04472ZM7.73271 4.29059C7.93716 4.29059 8.12102 4.41506 8.19695 4.60489L9.86042 8.76357C9.92204 8.91762 9.90323 9.09219 9.81021 9.22958C9.7172 9.36697 9.56209 9.44927 9.39618 9.44927H6.06924C5.90333 9.44927 5.74822 9.36697 5.6552 9.22958C5.56219 9.09219 5.54338 8.91762 5.605 8.76357L7.26847 4.60489C7.3444 4.41506 7.52826 4.29059 7.73271 4.29059ZM6.80775 8.44927H8.65766L7.73271 6.13688L6.80775 8.44927Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
