type MultiPreviewIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
};

export function MultiPreviewIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
}: MultiPreviewIconProps) {
  return (
    <span className={addClassName}>
      <svg
        style={{
          maxWidth: width + "px",
          maxHeight: height + "px",
          width: "100%",
        }}
        viewBox="0 0 152 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="151"
          height="151"
          rx="7.5"
          stroke="#A0A0A0"
        />
        <g filter="url(#filter0_d_4825_3487)">
          <rect x="50" y="37" width="65" height="65" rx="8" fill="black" />
          <rect
            x="50.5"
            y="37.5"
            width="64"
            height="64"
            rx="7.5"
            stroke="#A0A0A0"
          />
        </g>
        <g filter="url(#filter1_d_4825_3487)">
          <rect x="37" y="49" width="65" height="65" rx="8" fill="black" />
          <rect
            x="37.5"
            y="49.5"
            width="64"
            height="64"
            rx="7.5"
            stroke="#A0A0A0"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_4825_3487"
            x="25"
            y="16"
            width="115"
            height="115"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4825_3487"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4825_3487"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_4825_3487"
            x="12"
            y="28"
            width="115"
            height="115"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4825_3487"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4825_3487"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </span>
  );
}
