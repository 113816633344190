export function ZapGridIcon() {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16.0898" r="15.5" fill="black" stroke="#2C2C2C" />
      <rect
        x="9"
        y="9.08984"
        width="5.6"
        height="5.6"
        rx="1.86667"
        stroke="#E0E0E0"
        stroke-width="1.2"
      />
      <rect
        x="17.4004"
        y="9.08984"
        width="5.6"
        height="5.6"
        rx="1.86667"
        stroke="#E0E0E0"
        stroke-width="1.2"
      />
      <rect
        x="9"
        y="17.4922"
        width="5.6"
        height="5.6"
        rx="1.86667"
        stroke="#E0E0E0"
        stroke-width="1.2"
      />
      <rect
        x="17.4004"
        y="17.4922"
        width="5.6"
        height="5.6"
        rx="1.86667"
        stroke="#E0E0E0"
        stroke-width="1.2"
      />
    </svg>
  );
}
