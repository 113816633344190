type CollectionIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function CollectionIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: CollectionIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66667 1H2C1.44772 1 1 1.44771 1 2V4.66667C1 5.21895 1.44771 5.66667 2 5.66667H4.66667C5.21895 5.66667 5.66667 5.21895 5.66667 4.66667V2C5.66667 1.44772 5.21895 1 4.66667 1Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 1H9.33333C8.78105 1 8.33333 1.44771 8.33333 2V4.66667C8.33333 5.21895 8.78105 5.66667 9.33333 5.66667H12C12.5523 5.66667 13 5.21895 13 4.66667V2C13 1.44772 12.5523 1 12 1Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 8.33333H9.33333C8.78105 8.33333 8.33333 8.78105 8.33333 9.33333V12C8.33333 12.5523 8.78105 13 9.33333 13H12C12.5523 13 13 12.5523 13 12V9.33333C13 8.78105 12.5523 8.33333 12 8.33333Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.66667 8.33333H2C1.44772 8.33333 1 8.78105 1 9.33333V12C1 12.5523 1.44771 13 2 13H4.66667C5.21895 13 5.66667 12.5523 5.66667 12V9.33333C5.66667 8.78105 5.21895 8.33333 4.66667 8.33333Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
