export function LinkIcon() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54688 9.62204C7.86974 10.0537 8.28165 10.4108 8.75466 10.6692C9.22768 10.9277 9.75075 11.0813 10.2884 11.1198C10.826 11.1583 11.3656 11.0808 11.8707 10.8924C12.3757 10.704 12.8343 10.4092 13.2153 10.028L15.4707 7.77264C16.1554 7.0637 16.5343 6.11419 16.5257 5.12861C16.5172 4.14303 16.1219 3.20024 15.4249 2.50331C14.728 1.80637 13.7852 1.41105 12.7996 1.40249C11.814 1.39392 10.8645 1.7728 10.1556 2.45752L8.86251 3.74307M10.5049 8.04773C10.182 7.61611 9.77011 7.25897 9.29709 7.00054C8.82407 6.7421 8.30101 6.58843 7.76338 6.54992C7.22574 6.51142 6.68611 6.58899 6.1811 6.77737C5.67608 6.96576 5.21748 7.26056 4.83641 7.64176L2.58105 9.89712C1.89634 10.6061 1.51746 11.5556 1.52602 12.5412C1.53459 13.5267 1.92991 14.4695 2.62684 15.1665C3.32378 15.8634 4.26657 16.2587 5.25214 16.2673C6.23772 16.2758 7.18724 15.897 7.89618 15.2122L9.18173 13.9267"
        stroke="#E0E0E0"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
