import { createBrowserRouter } from "react-router-dom";
import Root from "./root.component";
import { Media } from "./examples/Media";
import { Home } from "./Home";
import { Zaps } from "./examples/Zaps";

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/media",
          element: <Media />,
        },
        {
          path: "/zaps",
          element: <Zaps />,
        },
      ],
    },
  ],
  {
    basename: "/home",
  }
);
