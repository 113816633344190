import { TrashIcon } from "../../../icons/TrashIcon";

export function Trash() {
  return (
    <div className="text-center w-full flex items-center justify-center flex-col h-full">
      <p>
        <TrashIcon color="#AE70FF" width={60} height={64} />
      </p>
      <div className="font-semibold">
        <p className="mt-4">Unsplash assets not found</p>
        <p className="text-[#AE70FF] underline mt-2">
          Please adjust your search query
        </p>
      </div>
    </div>
  );
}
