export function ShareIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8738 12.0273C15.8738 11.5441 15.4821 11.1524 14.9989 11.1524C14.5156 11.1524 14.1239 11.5441 14.1239 12.0273H15.8738ZM4.07934 1.96481H4.9543V0.214879H4.07934V1.96481ZM14.1239 12.0273V13.8551H15.8738V12.0273H14.1239ZM14.1239 13.8551C14.1239 14.0535 13.9624 14.2149 13.7642 14.2149V15.9648C14.929 15.9648 15.8738 15.0198 15.8738 13.8551H14.1239ZM13.7642 14.2149H2.23469V15.9648H13.7642V14.2149ZM2.23469 14.2149C2.03644 14.2149 1.87496 14.0535 1.87496 13.8551H0.125036C0.125036 15.0198 1.06984 15.9648 2.23469 15.9648V14.2149ZM1.87496 13.8551V2.32463H0.125036V13.8551H1.87496ZM1.87496 2.32463C1.87496 2.12619 2.03644 1.96481 2.23469 1.96481V0.214879C1.06984 0.214879 0.125036 1.15987 0.125036 2.32463H1.87496ZM2.23469 1.96481H4.07934V0.214879H2.23469V1.96481Z"
        fill="#E0E0E0"
      />
      <path
        d="M9.33008 0.214879H8.45511V1.96481H9.33008V0.214879ZM15 1.08984H15.875V0.214879H15V1.08984ZM14.1251 6.77734C14.1251 7.26057 14.5168 7.65231 15 7.65231C15.4833 7.65231 15.875 7.26057 15.875 6.77734H14.1251ZM9.33008 1.96481H15V0.214879H9.33008V1.96481ZM14.1251 1.08984V6.77734H15.875V1.08984H14.1251Z"
        fill="#E0E0E0"
      />
      <path
        d="M8.87451 7.21484L11.9368 4.15234L13.4679 2.62109L14.999 1.08984"
        stroke="#E0E0E0"
        stroke-width="1.74993"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
}
