export function InputIndicator() {
  return (
    <svg
      width="14"
      height="42"
      viewBox="0 0 14 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1H1C4.31371 1 7 3.68629 7 7V35C7 38.3137 4.31371 41 1 41H0"
        stroke="#00FFFF"
        stroke-width="2"
      />
      <path
        d="M14 1H13C9.68629 1 7 3.68629 7 7V35C7 38.3137 9.68629 41 13 41H14"
        stroke="#00FFFF"
        stroke-width="2"
      />
    </svg>
  );
}
