type PlusIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function PlusIcon({
  addClassName = "",
  width = 24,
  height = 24,
  color = "#FFFFFF",
}: PlusIconProps) {
  let padding = 3;

  let className =
    "inline-flex border rounded-full border-[" +
    color +
    "] items-center" +
    " " +
    addClassName;

  return (
    <span
      className={className}
      style={{
        padding: padding + "px",
      }}
    >
      <svg
        width={width - padding}
        height={height - padding}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 0.966669C5.27847 0.966669 5.50422 1.19242 5.50422 1.47089L5.50422 8.53001C5.50422 8.80849 5.27847 9.03424 5 9.03424C4.72152 9.03424 4.49578 8.80849 4.49578 8.53001L4.49578 1.47089C4.49578 1.19242 4.72153 0.966669 5 0.966669Z"
          fill={color}
        />
        <path
          d="M9.03378 5.00045C9.03378 5.27893 8.80804 5.50468 8.52956 5.50468L1.47044 5.50468C1.19196 5.50468 0.966215 5.27893 0.966215 5.00045C0.966215 4.72198 1.19196 4.49623 1.47044 4.49623L8.52956 4.49623C8.80804 4.49623 9.03378 4.72198 9.03378 5.00045Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
