type UnsplashIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function UnsplashIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: UnsplashIconProps) {
  return (
    <span className={addClassName}>
      <svg
        fill={color}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 6.75V0h9v6.75h-9zm9 3.75H24V24H0V10.5h7.5v6.75h9V10.5z" />
      </svg>
    </span>
  );
}
