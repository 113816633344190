export function CollapseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00503 3.50503C5.27839 3.23166 5.72161 3.23166 5.99497 3.50503C6.26834 3.77839 6.26834 4.22161 5.99497 4.49497L5.00503 3.50503ZM1.5 8L1.00503 8.49497C0.731658 8.22161 0.731658 7.77839 1.00503 7.50503L1.5 8ZM5.99497 11.505C6.26834 11.7784 6.26834 12.2216 5.99497 12.495C5.72161 12.7683 5.27839 12.7683 5.00503 12.495L5.99497 11.505ZM2 8.7C1.6134 8.7 1.3 8.3866 1.3 8C1.3 7.6134 1.6134 7.3 2 7.3L2 8.7ZM5.99497 4.49497L1.99497 8.49497L1.00503 7.50503L5.00503 3.50503L5.99497 4.49497ZM1.99497 7.50503L5.99497 11.505L5.00503 12.495L1.00503 8.49497L1.99497 7.50503ZM14.5 8.7L2 8.7L2 7.3L14.5 7.3L14.5 8.7Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
