export function VoteDownIcon() {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6585_4155)">
        <path
          d="M8.46 15.6798V11.5498H1.5L11.46 1.58984L21.43 11.5498"
          stroke="#E0E0E0"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <mask id="path-2-inside-1_6585_4155" fill="white">
          <path d="M12.9688 9.4375V15.6775H14.874V11.3828L12.9688 9.4375Z" />
        </mask>
        <path
          d="M12.9688 9.4375L14.3976 8.03807L10.9688 4.53717V9.4375H12.9688ZM12.9688 15.6775H10.9688V17.6775H12.9688V15.6775ZM14.874 15.6775V17.6775H16.874V15.6775H14.874ZM14.874 11.3828H16.874V10.5665L16.3029 9.98338L14.874 11.3828ZM10.9688 9.4375V15.6775H14.9688V9.4375H10.9688ZM12.9688 17.6775H14.874V13.6775H12.9688V17.6775ZM16.874 15.6775V11.3828H12.874V15.6775H16.874ZM16.3029 9.98338L14.3976 8.03807L11.5399 10.8369L13.4452 12.7822L16.3029 9.98338Z"
          fill="#E0E0E0"
          mask="url(#path-2-inside-1_6585_4155)"
        />
      </g>
      <defs>
        <clipPath id="clip0_6585_4155">
          <rect
            width="22.49"
            height="15.59"
            fill="white"
            transform="translate(0 0.0898438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
