import { EventData, MediaItem } from "../../../../../utils/Types";
import { useEffect, useState } from "react";
import { Accordion } from "../../../elements/Accordion";
import { formatBytes } from "../../../../../utils/Units";
import { convertDatetime } from "../../../../../utils/Dates";
import { PlusIcon } from "../../../../icons/PlusIcon";

type SingleSelectionProps = {
  selected: MediaItem;
};

export function SingleSelection({ selected }: SingleSelectionProps) {
  const [accordions, setAccordions] = useState({
    media: true,
    tags: false,
  });

  const toggleAccordion = (e: EventData<any>, type) => {
    const newAccordions = { ...accordions };
    newAccordions[type] = !newAccordions[type];
    setAccordions(newAccordions);
  };

  const [current, setCurrent] = useState<MediaItem>(selected);
  useEffect(() => {
    setCurrent(selected);
  }, [selected]);

  if (!current) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div className="w-full mb-4 p-2 text-center">
        <img
          src={current.thumbnail_url}
          alt={current.file_name}
          className="rounded-lg inline-block max-w-[200px] md:max-w-[85%]"
        />
      </div>
      <div className="w-full mt-6">
        <h2 className="text-center mb-6">{current.file_name}</h2>
        <div className="w-full">
          <Accordion
            title="Media"
            isExpanded={accordions.media}
            onClick={(e: EventData<any>) => {
              toggleAccordion(e, "media");
            }}
          >
            <div className="pt-4 pb-2">
              <div className="flex justify-between mb-1">
                <div>Size</div>
                <div>{formatBytes(current.size)}</div>
              </div>
              <div className="flex justify-between mb-1">
                <div>Type</div>
                <div>{current.type}</div>
              </div>
              <div className="flex justify-between mb-1">
                <div>Date added</div>
                <div>{convertDatetime(current.created_at)}</div>
              </div>
              {current.dimensions.length > 1 ? (
                <div className="flex justify-between">
                  <div>Dimensions</div>
                  <div>
                    {current.dimensions[0]}x{current.dimensions[1]}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Accordion>
          <Accordion
            title="Tags"
            isExpanded={accordions.tags}
            onClick={(e: EventData<any>) => {
              toggleAccordion(e, "tags");
            }}
          >
            <div className="pt-4 w-full">
              <div className="justify-between mr-1 mb-1 inline-flex items-center rounded-2xl border border-white px-1 py-1 hover:bg-[#262626] cursor-pointer">
                <PlusIcon width={14} height={14} color="#ffffff" />
                <span className="ml-1 mr-1">Add Tag</span>
              </div>
              {current.tags.map((tag) => (
                <div
                  key={"tag-" + tag.id}
                  className="justify-between mr-1 mb-1 inline-flex items-center rounded-2xl border border-white px-1 py-1 hover:bg-[#262626] cursor-pointer"
                >
                  <span className="ml-1 mr-1">{tag.name}</span>
                </div>
              ))}
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
