export function GoogleDriveIcon(props) {
  const { isBigVersion } = props;
  return (
    <svg
      width={isBigVersion ? "48" : "16"}
      height={isBigVersion ? "48" : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.11483 2.69334L7.52631 6.87022L3.41195 14L1 9.82391L5.11483 2.69334ZM6.62194 9.82391H14.8538L12.4418 14H4.21087L6.62194 9.82391ZM9.63015 9.13116L5.51494 2H10.3388L14.454 9.13116H9.63015Z"
        fill={isBigVersion ? "#AE70FF" : "#E0E0E0"}
      />
    </svg>
  );
}
