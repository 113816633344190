import { CollectionIcon } from "../../../icons/CollectionIcon";
import { useState } from "react";
import { Search } from "./toolbar/Search";
import { Controls } from "./toolbar/Controls";
import {
  ControlType,
  EventData,
  MediaItem,
  MediaTag,
  PreviewEventData,
} from "../../../../utils/Types";
import { CollectionItem } from "./CollectionItem";
import { CollectionDetails } from "./CollectionDetails";

export function Collection() {
  const tags: MediaTag[] = [
    {
      id: 1,
      name: "Vintage",
      slug: "vintage",
    },
    {
      id: 1,
      name: "Water",
      slug: "water",
    },
    {
      id: 1,
      name: "Forecast",
      slug: "forecast",
    },
  ];

  const [items, setItems] = useState<MediaItem[]>([
    {
      id: 1,
      file_name: "file_title1.jpg",
      thumbnail_url: "https://picsum.photos/300/200?test=1",
      type: "image/jpg",
      size: 3242880,
      created_at: "2024-10-01 10:00:00",
      dimensions: [1024, 680],
      tags: tags
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * tags.length)),
    },
    {
      id: 2,
      file_name: "file_title2.jpg",
      thumbnail_url: "https://picsum.photos/300/200?test=2",
      type: "image/jpg",
      size: 4242880,
      created_at: "2024-10-01 10:00:00",
      dimensions: [1000, 720],
      tags: tags
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * tags.length)),
    },
    {
      id: 3,
      file_name: "file_title3.jpg",
      thumbnail_url: "https://picsum.photos/300/200?test=1",
      type: "image/jpg",
      size: 5242880,
      created_at: "2024-10-01 10:00:00",
      dimensions: [990, 550],
      tags: tags
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * tags.length)),
    },
    {
      id: 4,
      file_name: "file_title4.jpg",
      thumbnail_url: "https://picsum.photos/300/200?test=2",
      type: "image/jpg",
      size: 6242880,
      created_at: "2024-10-01 10:00:00",
      dimensions: [1280, 820],
      tags: tags
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * tags.length)),
    },
    {
      id: 5,
      file_name: "file_title5.jpg",
      thumbnail_url: "https://picsum.photos/300/200?test=1",
      type: "image/jpg",
      size: 1242880,
      created_at: "2024-10-01 10:00:00",
      dimensions: [1384, 885],
      tags: tags
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * tags.length)),
    },
    {
      id: 6,
      file_name: "file_title6.jpg",
      thumbnail_url: "https://picsum.photos/300/200?test=2",
      type: "image/jpg",
      size: 7242880,
      created_at: "2024-10-01 10:00:00",
      dimensions: [768, 430],
      tags: tags
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.floor(Math.random() * tags.length)),
    },
  ]);

  const [selection, setSelection] = useState([]);
  const [controlsVisibility, setControlsVisibility] = useState({});
  const [showSelection, setShowSelection] = useState(false);

  const isSelected = (item) => {
    let items = [...selection];
    return items.find((x) => x.id === item.id) !== undefined;
  };

  const select = (item) => {
    if (isSelected(item)) {
      return false;
    }
    let newItems = [...selection];
    newItems.push(item);
    setSelection(newItems);
    return true;
  };

  const unselect = (item) => {
    if (!isSelected(item)) {
      return false;
    }
    let items = [...selection];
    items = items.filter((x) => x.id != item.id);
    setSelection(items);
    return true;
  };

  const setControlsVisibilityState = (key: string, isHidden: boolean) => {
    let controlsVisibilityNew = { ...controlsVisibility };
    controlsVisibilityNew[key] = isHidden;
    setControlsVisibility(controlsVisibilityNew);
  };

  const onItemClick = (e: EventData<MediaItem>) => {
    if (isSelected(e.data)) {
      unselect(e.data);
    } else {
      select(e.data);
    }
  };

  const onControlClick = (e: EventData<ControlType>) => {
    console.info("Control Click: " + e.data.key);

    switch (e.data.key) {
      case "details":
        setShowSelection(true);
        setControlsVisibilityState("details", false); // isHidden?
        break;
      default:
        console.warn('Control "' + e.data.key + '" not implemented yet.');
    }
  };

  const onPreviewCloseClick = (e: EventData<MediaItem>) => {
    setShowSelection(false);
    setControlsVisibilityState("details", true);
  };
  const onPreviewActionClick = (e: PreviewEventData<any>) => {};

  if (items.length > 0) {
    return (
      <div className="flex xl:flex-row flex-col auto-rows-min h-full">
        <div
          className={
            showSelection
              ? "xl:max-w-[75%] lg:max-w-full md:max-w-full max-w-full w-full flex-grow border-r border-r-white"
              : "w-full"
          }
        >
          <div>
            <div className="flex flex-col md:flex-row p-4 border-b-white border-b">
              <div className="flex w-full mb-4 md:mb-0 md:w-[50%]">
                <Search placeholder="Search Collection..." />
              </div>
              <div className="flex w-full md:w-[50%] justify-end">
                <Controls
                  onClick={onControlClick}
                  visibility={controlsVisibility}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-y-auto h-auto p-10">
              {items.map((item, index) => (
                <CollectionItem
                  key={index}
                  item={item}
                  selected={selection.length ? isSelected(item) : false}
                  onClick={onItemClick}
                />
              ))}
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line prettier/prettier */}
        <div
          className={
            showSelection
              ? "xl:max-w-[25%] lg:max-w-full md:max-w-full border-t border-t-white max-w-full w-full p-4"
              : "hidden"
          }
        >
          <CollectionDetails
            selected={selection}
            onAction={onPreviewActionClick}
            onClose={onPreviewCloseClick}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex w-full flex-col text-center items-center justify-center h-full">
        <p>
          <CollectionIcon color="#AE70FF" width={60} height={60} />
        </p>
        <div className="font-semibold">
          <p className="mt-4">You have no files uploaded yet</p>
          <p className="mt-2">
            <u>Upload Media</u> to create your collection
          </p>
        </div>
      </div>
    );
  }
}
