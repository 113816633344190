export function ZapCloseIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="15"
        stroke="#6c00fa"
        stroke-width="2"
      />
      <path
        d="M10.2954 10.2954C10.6892 9.90154 11.3277 9.90154 11.7215 10.2954L21.7046 20.2785C22.0985 20.6723 22.0985 21.3108 21.7046 21.7046C21.3108 22.0985 20.6723 22.0985 20.2785 21.7046L10.2954 11.7215C9.90154 11.3277 9.90154 10.6892 10.2954 10.2954Z"
        fill="#E0E0E0"
      />
      <path
        d="M21.7046 10.2954C22.0985 10.6892 22.0985 11.3277 21.7046 11.7215L11.7215 21.7046C11.3277 22.0985 10.6892 22.0985 10.2954 21.7046C9.90154 21.3108 9.90154 20.6723 10.2954 20.2785L20.2785 10.2954C20.6723 9.90154 21.3108 9.90154 21.7046 10.2954Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
