export function ExpandIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9964 3.50503C10.7231 3.23166 10.2799 3.23166 10.0065 3.50503C9.73312 3.77839 9.73312 4.22161 10.0065 4.49497L10.9964 3.50503ZM14.5015 8L14.9964 8.49497C15.2698 8.22161 15.2698 7.77839 14.9964 7.50503L14.5015 8ZM10.0065 11.505C9.73312 11.7784 9.73312 12.2216 10.0065 12.495C10.2799 12.7683 10.7231 12.7683 10.9964 12.495L10.0065 11.505ZM14.0015 8.7C14.3881 8.7 14.7015 8.3866 14.7015 8C14.7015 7.6134 14.3881 7.3 14.0015 7.3L14.0015 8.7ZM10.0065 4.49497L14.0065 8.49497L14.9964 7.50503L10.9964 3.50503L10.0065 4.49497ZM14.0065 7.50503L10.0065 11.505L10.9964 12.495L14.9964 8.49497L14.0065 7.50503ZM1.50146 8.7L14.0015 8.7L14.0015 7.3L1.50146 7.3L1.50146 8.7Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
