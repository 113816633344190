export function BoldIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9H10C11.0609 9 12.0783 8.57857 12.8284 7.82843C13.5786 7.07828 14 6.06087 14 5C14 3.93913 13.5786 2.92172 12.8284 2.17157C12.0783 1.42143 11.0609 1 10 1H2V9ZM2 9H11C12.0609 9 13.0783 9.42143 13.8284 10.1716C14.5786 10.9217 15 11.9391 15 13C15 14.0609 14.5786 15.0783 13.8284 15.8284C13.0783 16.5786 12.0609 17 11 17H2V9Z"
        stroke="#E0E0E0"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
