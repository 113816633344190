export function BgFontColorIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.89543 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895431 17.1046 0 16 0H2ZM9.00175 4.30078C9.28798 4.30078 9.54538 4.47505 9.65168 4.74081L12.8517 12.7408C12.9953 13.0998 12.8207 13.5071 12.4617 13.6507C12.1028 13.7943 11.6954 13.6197 11.5518 13.2608L10.6606 11.0328H7.34286L6.45168 13.2608C6.3081 13.6197 5.90072 13.7943 5.54178 13.6507C5.18283 13.5071 5.00824 13.0998 5.15181 12.7408L8.35182 4.74081C8.45812 4.47505 8.71552 4.30078 9.00175 4.30078ZM9.00175 6.88559L10.1006 9.63281H7.90286L9.00175 6.88559Z"
        fill="#00FFFF"
        fill-opacity="0.6"
      />
    </svg>
  );
}
