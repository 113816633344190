import { ControlType, EventData, MediaItem } from "../../../../utils/Types";
import { useEffect, useState } from "react";

type ItemProps = {
  onClick?(event: EventData<MediaItem>): void;
  item: MediaItem;
  selected: boolean;
};

export function CollectionItem({ item, onClick, selected = false }: ItemProps) {
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleClick = (e, item) => {
    if (onClick) {
      const params: EventData<MediaItem> = {
        event: e,
        data: item,
      };
      onClick(params);
    }
  };

  return (
    <div key={item.file_name}>
      <div
        role="presentation"
        className={
          (isSelected ? "border-2 border-[#00FFFF] rounded-lg" : "") +
          " hover:cursor-pointer py-4 px-1"
        }
        onClick={(e) => {
          handleClick(e, item);
        }}
      >
        <div className="border-4 border-white text-center">
          <img
            src={item.thumbnail_url}
            alt={item.file_name}
            className="inline-block"
          />
        </div>
      </div>
      <div className="w-full text-center">
        <div
          className={
            (isSelected ? "bg-[#2961D9]" : "") +
            " px-3 py-1 mt-2 inline-block text-xs rounded-lg"
          }
        >
          {item.file_name}
        </div>
      </div>
    </div>
  );
}
