type EyeCloseIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function EyeCloseIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: EyeCloseIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.455 13.4536C12.1729 14.4308 10.6118 14.9722 9 14.9986C3.75 14.9986 0.75 8.99855 0.75 8.99855C1.68292 7.25998 2.97685 5.74101 4.545 4.54355M7.425 3.17855C7.94125 3.05771 8.4698 2.99731 9 2.99855C14.25 2.99855 17.25 8.99855 17.25 8.99855C16.7947 9.85026 16.2518 10.6521 15.63 11.3911M10.59 10.5886C10.384 10.8096 10.1356 10.9869 9.85961 11.1099C9.58362 11.2329 9.28568 11.299 8.98357 11.3043C8.68146 11.3097 8.38137 11.2541 8.10121 11.1409C7.82104 11.0278 7.56654 10.8593 7.35288 10.6457C7.13923 10.432 6.97079 10.1775 6.85763 9.89735C6.74447 9.61718 6.68889 9.31709 6.69423 9.01499C6.69956 8.71288 6.76568 8.41494 6.88866 8.13894C7.01163 7.86294 7.18894 7.61454 7.41 7.40855M0.750086 0.75L17.2501 17.25"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
