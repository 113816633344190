import { ChangeEventHandler, useEffect, useState } from "react";
import { MagnifierIcon } from "../../../../icons/MagnifierIcon";
import useDebounce from "../../../../../utils/Debounce";

type SearchBarProps = {
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export function Search({ placeholder = "", onChange = null }: SearchBarProps) {
  const [input, setInput] = useState("");
  const [debounceVal, setDebounceVal] = useState("");
  const debounceValue = useDebounce(input, 2000);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setDebounceVal(input);
  }, [debounceValue, input]);

  useEffect(() => {
    if (debounceVal.length === 0) {
      setIsExpanded(false);
    }
  }, [debounceVal]);

  const handleInput = (e) => {
    setInput(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  const handleMouseOver = () => {
    setIsExpanded(true);
  };

  const handleMouseOut = () => {
    setTimeout(() => {
      if (debounceVal.length === 0) {
        setIsExpanded(false);
      }
    }, 5000);
  };

  let classes =
    "inline-flex rounded-xl bg-gradient-to-r from-[#00FFFF80] to-[#6C00FA80] px-4 py-2 items-center text-sm" +
    (isExpanded
      ? " w-[250px] transition-all"
      : " w-auto overflow-hidden cursor-pointer");

  return (
    <div
      onFocus={handleMouseOver}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
      className={classes}
    >
      <MagnifierIcon color="#ffffff" width={20} height={20} />

      <input
        type="text"
        className={
          (isExpanded ? "" : "hidden") +
          " bg-transparent border-0 placeholder-[#ffffff] ml-2 outline-none"
        }
        placeholder={placeholder}
        onInput={handleInput}
      />
    </div>
  );
}
