export function EpicIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1791 9.29879C17.9304 9.35594 17.7245 9.5192 17.5186 9.66614C16.412 10.507 15.3054 11.3559 14.2074 12.1967L12.5861 8.09064C12.3373 7.64166 11.6597 7.64166 11.4109 8.09064L9.7896 12.1967C8.68301 11.3559 7.57642 10.507 6.4784 9.66614C6.2811 9.51104 6.06664 9.35594 5.81788 9.29879C5.56911 9.24165 5.26887 9.29879 5.13162 9.51104C5.02868 9.68247 5.05441 9.88655 5.08015 10.0825C5.51029 12.9251 5.94784 15.7606 6.37904 18.6022C6.43954 19.0009 6.72845 19.3291 7.12083 19.4222C8.68437 19.7932 10.4217 20.0008 12.2516 20.0008C13.863 20.0008 15.398 19.8371 16.8072 19.5463C17.2131 19.4626 17.5164 19.129 17.5788 18.7193C18.0174 15.842 18.4622 12.9587 18.8997 10.0825C18.9254 9.88655 18.9512 9.68247 18.8482 9.51104C18.7196 9.29879 18.4107 9.23349 18.162 9.29879H18.1791Z"
        fill="#E0E0E0"
      />
      <path
        d="M6.1274 6.64505C6.1274 6.08995 5.6556 5.63281 5.0637 5.63281C4.4718 5.63281 4 6.08179 4 6.64505C4 7.20831 4.4718 7.65729 5.0637 7.65729C5.6556 7.65729 6.1274 7.20831 6.1274 6.64505Z"
        fill="#E0E0E0"
      />
      <path
        d="M12.0012 6.02448C12.5845 6.02448 13.0649 5.5755 13.0649 5.01224C13.0649 4.44898 12.5931 4 12.0012 4C11.4093 4 10.9375 4.44898 10.9375 5.01224C10.9375 5.5755 11.4093 6.02448 12.0012 6.02448Z"
        fill="#E0E0E0"
      />
      <path
        d="M18.9333 5.63281C18.35 5.63281 17.8696 6.08179 17.8696 6.64505C17.8696 7.20831 18.3414 7.65729 18.9333 7.65729C19.5252 7.65729 19.997 7.20831 19.997 6.64505C19.997 6.08179 19.5252 5.63281 18.9333 5.63281Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
