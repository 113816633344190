type CityVerseIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function CityVerseIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: CityVerseIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8903 8.14625H1.10974M12.8916 5.86211H1.10789M2.06463 3.58664H11.9349C10.8518 2.02359 9.04534 1 7 1C3.68624 1 1 3.68624 1 7C1 10.3138 3.68624 13 7 13C9.04165 13 10.8454 11.9801 11.9289 10.4221H2.07108"
          stroke={color}
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    </span>
  );
}
