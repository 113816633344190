import dayjs from "dayjs";

export const convertDatetime = (
  date: string,
  srcFormat: string = "YYYY-MM-DD HH:mm:ss",
  dstFormat: string = "MM/DD/YYYY"
) => {
  let dt = dayjs(date);
  return dt.format(dstFormat);
};
