export function UnderlineIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 1V7.46154C4 8.93043 4.57946 10.3392 5.61091 11.3778C6.64236 12.4165 8.04131 13 9.5 13C10.9587 13 12.3576 12.4165 13.3891 11.3778C14.4205 10.3392 15 8.93043 15 7.46154V1M1 17H17"
        stroke="#E0E0E0"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
