import {
  EventData,
  MediaItem,
  PreviewEventData,
} from "../../../../utils/Types";
import { useEffect, useState } from "react";
import { SingleSelection } from "./details/SingleSelection";
import { ZeroSelection } from "./details/ZeroSelection";
import { MultiSelection } from "./details/MultiSelection";
import { EyeCloseIcon } from "../../../icons/EyeCloseIcon";

type CollectionDetailsProps = {
  selected: MediaItem[];
  onAction?(event: PreviewEventData<any>): void;
  onClose?(event: EventData<MediaItem>): void;
};

export function CollectionDetails({
  selected = [],
  onAction,
  onClose,
}: CollectionDetailsProps) {
  const [current, setCurrent] = useState<MediaItem[]>(selected);

  useEffect(() => {
    setCurrent(selected);
  }, [selected]);

  let preview =
    selected.length === 1 ? (
      <SingleSelection selected={current[0]} />
    ) : selected.length > 1 ? (
      <MultiSelection selected={current} />
    ) : (
      <ZeroSelection />
    );

  return (
    <div className="p-2">
      <div
        className="flex justify-end w-full cursor-pointer mb-6"
        role="presentation"
        onClick={(e) => {
          const params: EventData<any> = {
            event: e,
            data: null,
          };
          onClose(params);
        }}
      >
        <EyeCloseIcon width={20} height={20} />
      </div>
      <>{preview}</>
    </div>
  );
}
