export function DropBoxIcon(props) {
  const { isBigVersion } = props;
  return (
    <svg
      width={isBigVersion ? "48" : "16"}
      height={isBigVersion ? "48" : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0006 3L2 4.91225L5.0006 6.82271L8 4.91225L5.0006 3ZM11 3L8 4.91225L11 6.82271L14 4.91225L11 3ZM2 8.73376L5.0006 10.6448L8 8.73376L5.0006 6.82271L2 8.73376ZM11 6.82271L8 8.73376L11 10.6448L14 8.73376L11 6.82271ZM5.0006 11.2816L8 13.1939L11 11.2816L8 9.37118L5.0006 11.2816Z"
        fill={isBigVersion ? "#AE70FF" : "#E0E0E0"}
      />
    </svg>
  );
}
