type AddIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
};

export function AddIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
}: AddIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 22.752C18.3848 22.752 22.75 18.3867 22.75 13.002C22.75 7.61718 18.3848 3.25195 13 3.25195C7.61522 3.25195 3.25 7.61718 3.25 13.002C3.25 18.3867 7.61522 22.752 13 22.752Z"
          stroke="#E0E0E0"
          stroke-width="1.9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 9.09961V16.8996"
          stroke="#E0E0E0"
          stroke-width="1.9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.09961 13.002H16.8996"
          stroke="#E0E0E0"
          stroke-width="1.9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
