import { NewFolderIcon } from "../../../../icons/NewFolderIcon";
import { FilterIcon } from "../../../../icons/FilterIcon";
import { OrderDateDescIcon } from "../../../../icons/OrderDateDescIcon";
import { ViewIcon } from "../../../../icons/ViewIcon";
import {
  ControlType,
  DropdownItem,
  EventData,
} from "../../../../../utils/Types";
import { EyeOpenIcon } from "../../../../icons/EyeOpenIcon";
import { Key, useEffect, useState } from "react";
import { Dropdown } from "./Dropdown";

type ControlsProps = {
  onClick?(event: EventData<ControlType>): void;
  width?: number;
  height?: number;
  visibility?: object;
  items?: string[];
};

export function Controls({
  onClick = null,
  width = 28,
  height = 28,
  visibility = {},
}: ControlsProps) {
  const [visibleDropdown, setVisibleDropdown] = useState<string>("");

  const filteringItems: DropdownItem[] = [
    { key: "images", icon: "", name: "Images" },
    { key: "videos", icon: "", name: "Videos" },
  ];

  const orderingItems: DropdownItem[] = [
    { key: "newest", icon: "", name: "Newest" },
    { key: "oldest", icon: "", name: "Oldest" },
    { key: "name-asc", icon: "", name: "Name A-Z" },
    { key: "name-desc", icon: "", name: "Name Z-A" },
  ];

  const viewingItems: DropdownItem[] = [
    { key: "grid-large", icon: "", name: "Grid Large" },
    { key: "grid-small", icon: "", name: "Grid Small" },
    { key: "list", icon: "", name: "List" },
  ];

  const onFilteringDropdownClick = (e: EventData<DropdownItem>) => {
    console.info("new filter: " + e.data.key);
  };

  const onOrderingDropdownClick = (e: EventData<DropdownItem>) => {
    console.info("new order: " + e.data.key);
  };

  const onViewingDropdownClick = (e: EventData<DropdownItem>) => {
    console.info("new view: " + e.data.key);
  };

  let controls: ControlType[] = [
    {
      key: "new_folder",
      type: "control",
      icon: <NewFolderIcon width={width} height={height} />,
    },
    {
      key: "divider1",
      type: "divider",
      icon: null,
    },
    {
      key: "filters",
      type: "control",
      icon: <FilterIcon width={width} height={height} />,
      items: (
        <Dropdown items={filteringItems} onClick={onFilteringDropdownClick} />
      ),
    },
    {
      key: "order",
      type: "control",
      icon: <OrderDateDescIcon width={width} height={height} />,
      items: (
        <Dropdown items={orderingItems} onClick={onOrderingDropdownClick} />
      ),
    },
    {
      key: "view",
      type: "control",
      icon: <ViewIcon width={width} height={height} />,
      items: <Dropdown items={viewingItems} onClick={onViewingDropdownClick} />,
    },
    {
      key: "details.divider",
      type: "divider",
      icon: null,
    },
    {
      key: "details",
      type: "control",
      icon: <EyeOpenIcon width={width} height={height} />,
    },
  ];

  const handleClick = (e: any, item: ControlType) => {
    if (item?.items) {
      setVisibleDropdown(visibleDropdown === item.key ? "" : item.key);
    } else {
      if (onClick) {
        const params: EventData<any> = {
          event: e,
          data: item,
        };
        onClick(params);
      }
    }
  };

  let [controlsVisibility, setControlsVisibility] = useState(visibility);
  useEffect(() => {
    setControlsVisibility(visibility);
  }, [visibility]);

  const isHidden = (item: ControlType): boolean => {
    let key1 = item.key.split(".")[0];

    let state = false;
    for (let key in controlsVisibility) {
      if (key1 === key && false === controlsVisibility[key]) {
        state = true;
        break;
      }
    }
    return state;
  };

  const showItem = (item: ControlType, index: Key) => {
    if (isHidden(item)) {
      return "";
    }

    if (item.type === "divider") {
      return (
        <div
          key={index}
          className="w-[2px] h-[24px] mx-4 inline-block bg-white"
        ></div>
      );
    } else {
      return (
        <div
          role="presentation"
          className="inline-flex items-center cursor-pointer relative"
          key={index}
          onClick={(e) => {
            handleClick(e, item);
          }}
        >
          {item.icon}
          {visibleDropdown === item.key ? item.items : ""}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        {controls.map((item, index) => showItem(item, index))}
      </div>
    </>
  );
}
