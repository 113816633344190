import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function ResetPrompt() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState(null);
  const [resent, setResent] = useState(false);

  useEffect(() => {
    state?.email ? setEmail(state?.email) : navigate("/forgot-password");
  }, [state, navigate]);

  const resendPasswordResetLink = () => {
    setResent(true);
  };

  return (
    <div className="max-w-xl mx-auto my-4 px-4 md:px-0 relative h-[calc(100vh-256px)] flex flex-col justify-center z-10 text-center font-montserrat">
      <h1 className="text-white mt-8 mb-8 text-2xl font-medium leading-9 tracking-tight">
        {resent ? (
          <>We have resent an email to:</>
        ) : (
          <>A reset email link has been sent to:</>
        )}
      </h1>
      {email && <p className="text-primary mb-8">{email}</p>}
      <p className="text-white">
        If you don’t receive an email, try{" "}
        <button
          className="text-primary underline"
          onClick={resendPasswordResetLink}
        >
          resending.
        </button>
        <br></br> If you still don’t see it, please check your spam folder,
        verify you entered the correct email address, or contact{" "}
        <Link to={"support"} className="text-primary underline">
          support.
        </Link>
      </p>
      <Link
        to={"/sign-in"}
        className="text-center text-white mt-7 font-medium font-montserrat underline hover:text-primary"
      >
        Back to sign in
      </Link>
    </div>
  );
}
