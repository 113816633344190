export function DownIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.07162 8.7539C6.22758 8.59798 6.43908 8.5104 6.65961 8.5104C6.88013 8.5104 7.09163 8.59798 7.24759 8.7539L9.98625 11.4926L12.7249 8.7539C12.8016 8.67446 12.8934 8.61111 12.9949 8.56752C13.0963 8.52393 13.2055 8.50099 13.3159 8.50003C13.4263 8.49907 13.5358 8.52011 13.638 8.56193C13.7402 8.60375 13.8331 8.6655 13.9112 8.74359C13.9893 8.82167 14.051 8.91453 14.0928 9.01674C14.1346 9.11895 14.1557 9.22846 14.1547 9.33889C14.1538 9.44931 14.1308 9.55844 14.0872 9.65991C14.0437 9.76138 13.9803 9.85314 13.9009 9.92986L10.5742 13.2565C10.4183 13.4124 10.2068 13.5 9.98625 13.5C9.76572 13.5 9.55422 13.4124 9.39826 13.2565L6.07162 9.92986C5.91571 9.7739 5.82812 9.56241 5.82812 9.34188C5.82812 9.12135 5.91571 8.90986 6.07162 8.7539Z"
        fill="#6C6C6C"
      />
    </svg>
  );
}
