export function PinIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#222222" />
      <path
        d="M24 16.668C24 21.3346 18 25.3346 18 25.3346C18 25.3346 12 21.3346 12 16.668C12 15.0767 12.6321 13.5505 13.7574 12.4253C14.8826 11.3001 16.4087 10.668 18 10.668C19.5913 10.668 21.1174 11.3001 22.2426 12.4253C23.3679 13.5505 24 15.0767 24 16.668Z"
        stroke="#E0E0E0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 18.668C19.1046 18.668 20 17.7725 20 16.668C20 15.5634 19.1046 14.668 18 14.668C16.8954 14.668 16 15.5634 16 16.668C16 17.7725 16.8954 18.668 18 18.668Z"
        stroke="#E0E0E0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
