export function FontColorIcon() {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6583 0.377166C10.5671 0.149371 10.3465 0 10.1012 0C9.85583 0 9.6352 0.149371 9.54409 0.377166L4.74409 12.3772C4.62102 12.6848 4.77067 13.034 5.07834 13.1571C5.38601 13.2802 5.73519 13.1305 5.85826 12.8228L7.30817 9.19805H12.8942L14.3441 12.8228C14.4672 13.1305 14.8163 13.2802 15.124 13.1571C15.4317 13.034 15.5813 12.6848 15.4583 12.3772L10.6583 0.377166ZM12.4142 7.99805L10.1012 2.21555L7.78817 7.99805H12.4142Z"
        fill="#00FFFF"
        fill-opacity="0.6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16.6C0 15.9925 0.492487 15.5 1.1 15.5H19.1C19.7075 15.5 20.2 15.9925 20.2 16.6C20.2 17.2075 19.7075 17.7 19.1 17.7H1.1C0.492487 17.7 0 17.2075 0 16.6Z"
        fill="#00FFFF"
        fill-opacity="0.6"
      />
    </svg>
  );
}
