export function FacebookIcon(props) {
  const { isBigVersion } = props;
  return (
    <svg
      width={isBigVersion ? "48" : "16"}
      height={isBigVersion ? "48" : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.51319 14H9.03954V8.94112H11.3158L11.5659 6.42722H9.03954V5.15789C9.03954 4.80909 9.3223 4.52632 9.67112 4.52632H11.5659V2H9.67112C7.92701 2 6.51319 3.41384 6.51319 5.15789V6.42722H5.25004L5 8.94112H6.51319V14Z"
        fill={isBigVersion ? "#AE70FF" : "#E0E0E0"}
      />
    </svg>
  );
}
