import React, { useState } from "react";
import { ClockIcon } from "./ClockIcon";
import { CommentIcon } from "./CommentIcon";
import { FluentIcon } from "./FluentIcon";
import { MiniPinIcon } from "./MiniPinIcon";
import { PinIcon } from "./PinIcon";
import { ShareIcon } from "./ShareIcon";
import { VoteDownIcon } from "./VoteDownIcon";
import { VoteUpIcon } from "./VoteUpIcon";
import { ZapCloseIcon } from "./ZapCloseIcon";
import ZapContentSwitcher from "./ZapContentSwitcher";
import { ZapGridIcon } from "./ZapGridIcon";
import ZapRichTextEditor from "./ZapRichTextEditor";
import { EditorState } from "draft-js";

function ZapComposer() {
  const [showModal, setShowModal] = useState(false);
  const [showLocationInput, setShowLocationInput] = useState(false);
  const [locationValue, setLocationValue] = useState("");
  const [selectedLocationValue, setSelectedLocationValue] = useState("");
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [zapText, setZapText] = useState(
    EditorState.createEmpty().getCurrentContent()
  );

  const handleLocationValueChange = (event) => {
    setLocationValue(event.target.value);
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      setSelectedLocationValue(locationValue);
      setShowLocationInput(false);
    }
  };

  let wordCountProgress =
    "calc(251.2 - (251.2 * " +
    (zapText.getPlainText("\u0001").split(" ").length - 1).toString() +
    ") / 214)";

  return (
    <div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => setShowModal(true)}
      >
        Zippy-Zap
      </button>

      {showModal && (
        <div>
          <div className="w-[800px] min-h-[155px] h-auto mb-[20px] relative bg-black rounded-[32px] shadow-2xl shadow-[#444444]">
            <div className="w-9 h-9 left-[25px] top-[22px] absolute rounded-full border-2 border-white" />
            <div className="h-9 w-[710px] px-5 py-[6px] left-[50px] top-[22px] relative flex justify-between items-start gap-6">
              <div className="flex items-start gap-6">
                <div className="text-[#e0e0e0] text-base font-normal font-['Inter']">
                  Name Surname
                </div>
                {selectedLocationValue && (
                  <div className="w-[155px] top-[7px] relative justify-start items-center gap-1 inline-flex">
                    <MiniPinIcon />
                    <div className="text-right text-[#e0e0e0] text-xs font-normal font-['Inter'] leading-none">
                      {selectedLocationValue}
                    </div>
                  </div>
                )}
              </div>
              <div className="left-[25px] relative flex items-start gap-6">
                <div className="h-8 px-2 left-[0px] top-[0px] relative rounded-lg border border-[#2c2c2c] justify-center items-center inline-flex">
                  <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                    <div className="w-1 h-1 bg-[#d9d9d9] rounded-full" />
                    <div className="w-1 h-1 bg-[#d9d9d9] rounded-full" />
                    <div className="w-1 h-1 bg-[#d9d9d9] rounded-full" />
                  </div>
                </div>
                {!isPreviewMode && (
                  <button
                    className="w-8 h-8 left-[0px] top-[0px] rounded-[20px] relative hover:bg-[#6c00fa]"
                    onClick={() => setShowModal(false)}
                  >
                    <ZapCloseIcon />
                  </button>
                )}
                {isPreviewMode && (
                  <div className="w-8 h-8 left-[0px] top-[0px] relative">
                    <ZapGridIcon />
                  </div>
                )}
              </div>
            </div>
            <div className="w-[675px] relative flex items-start justify-center">
              <ZapRichTextEditor
                isPreviewMode={isPreviewMode}
                setZapText={setZapText}
              />
            </div>
            <div className="h-9 left-[24px] relative">
              <div className="h-4 px-4 py-2 left-[-25px] top-[0px] relative opacity-80 flex-col justify-start items-start gap-2.5 inline-flex">
                <hr className="w-[770px] border-[#444444]" />
              </div>
            </div>
            <div className="relative flex justify-between items-start">
              {!isPreviewMode && (
                <div className="top-[0px] mx-[20px] pb-[15px] relative flex justify-start- items-start">
                  <PinIcon />
                  <button
                    className={
                      showLocationInput
                        ? "left-[-35px] top-0 z-10 relative h-9 w-9 rounded-[20px] ring-1 ring-brand"
                        : "left-[-35px] top-0 z-10 relative h-9 w-9 rounded-[20px]"
                    }
                    onClick={() => setShowLocationInput(!showLocationInput)}
                  ></button>
                  {showLocationInput && (
                    <input
                      type="text"
                      value={locationValue}
                      onChange={handleLocationValueChange}
                      onKeyDown={handleKeyDownEvent}
                      placeholder="location"
                      className="w-[200px] h-[25px] left-[-30px] top-[5px] relative p-1 bg-[#2c2c2c] text-white text-sm font-normal font-['Inter'] ring-1 ring-brand focus:ring-brand"
                    ></input>
                  )}
                  <div className="left-[-20px] relative w-10 h-10">
                    <svg className="w-full h-full" viewBox="0 0 100 100">
                      {/* <!-- Background circle --> */}
                      <g transform="rotate(270,50,50)">
                        <circle
                          className="text-[#383838] stroke-current"
                          stroke-width="10"
                          cx="50"
                          cy="50"
                          r="40"
                          fill="transparent"
                        ></circle>
                        {/* <!-- Progress circle --> */}
                        <circle
                          className="text-[#1c9b9b]  progress-ring__circle stroke-current"
                          stroke-width="10"
                          stroke-linecap="round"
                          cx="50"
                          cy="50"
                          r="40"
                          fill="transparent"
                          stroke-dasharray="251.2"
                          stroke-dashoffset={wordCountProgress}
                        ></circle>
                      </g>

                      {/* <!-- Center text --> */}
                      <text
                        x="50"
                        y="50"
                        font-family="Verdana"
                        font-size="30"
                        text-anchor="middle"
                        alignment-baseline="middle"
                        fill="white"
                      >
                        {zapText.getPlainText("\u0001").split(" ").length - 1}
                      </text>
                    </svg>
                  </div>
                  <div className="top-[7px] right-3 relative text-white">
                    {zapText.getPlainText("\u0001").split(" ").length - 1} words
                  </div>
                </div>
              )}

              {isPreviewMode && (
                <div className="top-[0px] mx-[20px] pb-[18px] relative flex justify-start- items-start gap-2">
                  <button className="w-[36px] h-[36px] rounded-[36px] bg-[#2c2c2c] hover:bg-[#444444] transition duration-300 justify-center items-center flex">
                    <CommentIcon />
                  </button>
                  <button className="w-[36px] h-[36px] rounded-[36px] bg-[#2c2c2c] hover:bg-[#444444] transition duration-300 justify-center items-center flex">
                    <FluentIcon />
                  </button>

                  <div className="inline-block h-[35px] min-h-[1px] w-[1px] mx-4 self-stretch bg-[#444444] dark:bg-[#444444]/10"></div>
                  <button className="w-[36px] h-[36px] rounded-[36px] bg-[#2c2c2c] hover:bg-[#444444] transition duration-300 justify-center items-center flex">
                    <VoteUpIcon />
                  </button>
                  <button className="w-[36px] h-[36px] rounded-[36px] bg-[#2c2c2c] hover:bg-[#444444] transition duration-300 justify-center items-center flex">
                    <VoteDownIcon />
                  </button>

                  <div className="inline-block h-[35px] min-h-[1px] w-[1px] mx-4 self-stretch bg-[#444444] dark:bg-[#444444]/10"></div>
                  <button className="w-[36px] h-[36px] rounded-[36px] bg-[#2c2c2c] hover:bg-[#444444] transition duration-300 justify-center items-center flex">
                    <ShareIcon />
                  </button>
                </div>
              )}

              <div className="top-[0px] mx-[20px] pb-[20px] relative flex justify-start items-start gap-4">
                {isPreviewMode && (
                  <button
                    className="h-9 px-4 py-3 right-[0px] top-[0px] relative rounded-[20px] border border-[#2c2c2c] justify-center items-center gap-2.5 inline-flex"
                    onClick={() => setIsPreviewMode(false)}
                  >
                    <div className="text-white text-sm font-normal font-['Inter'] leading-none">
                      Edit
                    </div>
                  </button>
                )}
                {!isPreviewMode && (
                  <button
                    className="h-9 px-4 py-3 right-[0px] top-[0px] relative rounded-[20px] border border-[#2c2c2c] justify-center items-center gap-2.5 inline-flex"
                    onClick={() => setIsPreviewMode(true)}
                  >
                    <div className="text-white text-sm font-normal font-['Inter'] leading-none">
                      Preview
                    </div>
                  </button>
                )}
                <div className="w-[85px] h-9 right-[0px] top-[0px] relative">
                  <div className="h-9 pl-3 pr-2 py-3 left-0 top-0 absolute rounded-tl-[20px] rounded-bl-[20px] border border-[#6c00fa] hover:bg-[#6c00fa] justify-center items-center gap-2.5 inline-flex">
                    <ClockIcon />
                  </div>
                  <div className="h-9 pl-2 pr-3 py-3 left-[37px] top-0 absolute rounded-tr-[20px] rounded-br-[20px] border border-[#6c00fa] hover:bg-[#6c00fa] justify-center items-center gap-2.5 inline-flex">
                    <div className="text-white text-sm font-normal font-['Inter'] leading-none">
                      Post
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {zapText.getPlainText("\u0001").split(" ").length - 1 >= 214 && (
            <ZapContentSwitcher />
          )}
        </div>
      )}
    </div>
  );
}

export default ZapComposer;
