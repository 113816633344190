import React, { useState, useRef } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  Modifier,
} from "draft-js";
import getFragmentFromSelection from "draft-js/lib/getFragmentFromSelection";
import { BoldIcon } from "./BoldIcon";
import { ClockIcon } from "./ClockIcon";
import { InputIndicator } from "./InputIndicator";
import { ItalicizeIcon } from "./ItalicizeIcon";
import { LinkIcon } from "./LinkIcon";
import { ExpandIcon } from "./ExpandIcon";
import { PinIcon } from "./PinIcon";
import { QuoteIcon } from "./QuoteIcon";
import { TypingIcon } from "./TypingIcon";
import { UnderlineIcon } from "./UnderlineIcon";
import { ZapCloseIcon } from "./ZapCloseIcon";
import ZapContentSwitcher from "./ZapContentSwitcher";
import { onAddLink } from "./Link";
import { TooltipRootSVG } from "./TooltipRootSVG";
import { CollapseIcon } from "./CollapseIcon";
import { FontColorIcon } from "./FontColorIcon";
import { BgFontColorIcon } from "./BgFontColorIcon";
import { FontOutlineColor } from "./FontOutlineColorIcon";
import { FontShadowIcon } from "./FontShadowIcon";

function ZapRichTextEditor(props) {
  const { isPreviewMode, setZapText } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [defaultTextToggle, setDefaultTextToggle] = useState(true);
  const [linkUrl, setLinkUrl] = useState("");
  const [displayLink, setDisplayLink] = useState("");
  const [linkTooltip, setLinkTooltip] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [typingSelected, setTypingSelected] = useState(false);
  const [boldSelected, setBoldSelected] = useState(false);
  const [italicSelected, setItalicSelected] = useState(false);
  const [quoteSelected, setQuoteSelected] = useState(false);
  const [underlineSelected, setUnderlineSelected] = useState(false);
  const [showExpandedTools, setShowExpandedTools] = useState(false);
  const [fontColorSelected, setFontColorSelected] = useState(false);
  const [bgFontColorSelected, setBgFontColorSelected] = useState(false);
  const [fontOutlineColorSelected, setFontOutlineColorSelected] =
    useState(false);
  const [fontShadowSelected, setFontShadowSelected] = useState(false);

  const styleMap = {
    HIGHLIGHT: {
      backgroundColor: "#2BE2D8",
    },
    FONTCOLOR: {
      color: "#2BE2D8",
    },
    OUTLINE: {
      textShadow:
        "-1px -1px 0 #2BE2D8, 1px -1px 0 #2BE2D8, -1px 1px 0 #2BE2D8, 1px 1px 0 #2BE2D8",
    },
    SHADOW: {
      textShadow:
        "-3px -3px 0 #2BE2D8, 1px -1px 0 #2BE2D8, -1px 1px 0 #2BE2D8, 1px 1px 0 #2BE2D8",
    },
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const editorOnChange = (newState) => {
    setEditorState(newState);
    setZapText(newState.getCurrentContent());
  };

  const toggleInlineStyle = (e, style) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const removeDefaultText = () => {
    setDefaultTextToggle(false);
  };

  const handleLinkChange = (event) => {
    setLinkUrl(event.target.value);
  };

  const handleDisplayLinkChange = (event) => {
    setDisplayLink(event.target.value);
  };

  const handleSubmit = () => {
    // use the onAddLink(editorState, setEditorState, linkUrl, displayLink)
    onAddLink(editorState, setEditorState, linkUrl, displayLink);
  };

  const toggleLinkTooltip = () => {
    const selected = getFragmentFromSelection(editorState);
    const selectedText = selected
      ? selected.map((x) => x.getText()).join("")
      : "";
    if (selectedText) {
      setDisplayLink(selectedText);
    }
    setLinkTooltip(!linkTooltip);
  };

  const modifySelectedText = (inlineStyle) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    // Replace the selected text with the new text
    const newContentState = Modifier.applyInlineStyle(
      contentState,
      selection,
      inlineStyle
    );

    // Update the editor state with the new content
    return EditorState.push(editorState, newContentState, "replace-text");
  };

  return (
    <div>
      {defaultTextToggle && (
        <div className="flex items-start absolute pointer-events-none">
          <div className="left-[150px] top-[40px] relative">
            <InputIndicator />
          </div>
          <div className="left-[150px] top-[42px] relative bg-gradient-to-r from-[#00FFFF] from-0% to-[#6C00FA] bg-clip-text text-transparent text-[28px] font-['Avenir'] leading-9">
            Start typing here to get Zapping
          </div>
        </div>
      )}
      <div className="ml-[155px] mt-[40px]">
        <div
          role="presentation"
          // className="left-[50px] top-[0px] absolute w-[650px] text-[#e0e0e0] text-[28px] [&::-webkit-scrollbar]:hidden"
          className="text-[#e0e0e0] text-[28px] mb-[-20px]
          after:px-3.5
          after:py-2.5
          [&>textarea]:text-inherit
          after:text-inherit
          [&>textarea]:resize-none
          [&>textarea]:overflow-hidden
          [&>textarea]:[grid-area:a]
          after:[grid-area:a]
          after:whitespace-pre-wrap
          after:invisible
          after:content-[attr(data-cloned-val)_'_']
          after:border"
          onClick={removeDefaultText}
        >
          <Editor
            customStyleMap={styleMap}
            editorState={editorState}
            onChange={editorOnChange}
            onInput="this.parentNode.dataset.clonedVal = this.value"
            handleKeyCommand={handleKeyCommand}
            readOnly={isPreviewMode}
          />
        </div>
        <div className="w-[700px] left-[-15px] relative flex items-start justify-center">
          {!isPreviewMode && (
            <div className="bottom-0 p-4  mt-[20px] relative bg-[#212121] rounded-lg flex justify-center items-center gap-2">
              <button
                className={
                  typingSelected
                    ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                    : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                }
                onMouseDown={(e) => {
                  toggleInlineStyle(e, "TYPING");
                  setTypingSelected(!typingSelected);
                }}
              >
                <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                  <div className="ml-[-1px]">
                    <TypingIcon />
                  </div>
                </div>
              </button>
              <button
                className={
                  boldSelected
                    ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                    : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                }
                onMouseDown={(e) => {
                  toggleInlineStyle(e, "BOLD");
                  setBoldSelected(!boldSelected);
                }}
              >
                <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                  <BoldIcon />
                </div>
              </button>
              <button
                className={
                  italicSelected
                    ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                    : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                }
                onMouseDown={(e) => {
                  toggleInlineStyle(e, "ITALIC");
                  setItalicSelected(!italicSelected);
                }}
              >
                <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                  <ItalicizeIcon />
                </div>
              </button>
              <button
                className={
                  underlineSelected
                    ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                    : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                }
                onMouseDown={(e) => {
                  toggleInlineStyle(e, "UNDERLINE");
                  setUnderlineSelected(!underlineSelected);
                }}
              >
                <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                  <div className="ml-[-1px]">
                    <UnderlineIcon />
                  </div>
                </div>
              </button>
              <button
                className={
                  quoteSelected
                    ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                    : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                }
                onMouseDown={(e) => {
                  toggleInlineStyle(e, "QUOTE");
                  setQuoteSelected(!quoteSelected);
                }}
              >
                <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                  <QuoteIcon />
                </div>
              </button>
              <button
                className="w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] rounded-lg justify-center items-center flex"
                onClick={toggleLinkTooltip}
              >
                <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                  <div className="ml-[-1px]">
                    <LinkIcon />
                  </div>
                </div>
              </button>
              {linkTooltip && (
                <div className="left-[112px] top-[90px] z-10 absolute">
                  <div className="left-[159px] top-[-8px] absolute">
                    <TooltipRootSVG />
                  </div>

                  <div className="w-[330px] h-[184px] relative bg-[#212121] rounded-lg shadow text-white">
                    <input
                      type="text"
                      value={displayLink}
                      onChange={handleDisplayLinkChange}
                      placeholder={displayLink || "text"}
                      className="w-[250px] p-3 left-[64px] top-[16px] absolute bg-black rounded-lg border border-black/0 justify-start items-center inline-flex"
                    ></input>
                    <input
                      type="text"
                      value={linkUrl}
                      onChange={handleLinkChange}
                      placeholder="http://"
                      className="w-[250px] p-3 left-[64px] top-[72px] absolute bg-black rounded-lg border border-black/0 justify-start items-center inline-flex"
                    ></input>
                    <div className="px-2 py-3 left-[8px] top-[16px] absolute justify-start items-center gap-1 inline-flex">
                      <div className="w-8 text-[#a0a0a0] text-sm font-normal font-['Inter'] leading-none">
                        Text
                      </div>
                    </div>
                    <div className="px-2 py-3 left-[8px] top-[72px] absolute justify-start items-center gap-1 inline-flex">
                      <div className="w-8 text-[#a0a0a0] text-sm font-normal font-['Inter'] leading-none">
                        Link
                      </div>
                    </div>
                    <div className="left-[130px] top-[128px] absolute justify-end items-center gap-2 inline-flex">
                      <button
                        className="px-4 py-3 rounded-[10px] justify-end items-center gap-2.5 flex"
                        onClick={() => {
                          setLinkTooltip(false);
                        }}
                      >
                        <div className="text-white text-sm font-normal font-['Inter'] underline leading-none">
                          Cancel
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          handleSubmit();
                          setLinkTooltip(false);
                        }}
                        className="px-4 py-3 rounded-[10px] border border-[#444444] justify-end items-center gap-2.5 flex"
                      >
                        <div className="text-white text-sm font-normal font-['Inter'] leading-none">
                          Insert link
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showExpandedTools && (
                <>
                  <div className="inline-block h-[40px] min-h-[1px] w-[1px] m-1 self-stretch bg-[#444444] dark:bg-[#444444]/10"></div>

                  <button
                    className={
                      fontColorSelected
                        ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                        : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                    }
                    onMouseDown={(e) => {
                      toggleInlineStyle(e, "FONTCOLOR");
                      setFontColorSelected(!fontColorSelected);
                    }}
                  >
                    <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                      <div className="ml-[-2px]">
                        <FontColorIcon />
                      </div>
                    </div>
                  </button>
                  <button
                    className={
                      bgFontColorSelected
                        ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                        : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                    }
                    onMouseDown={(e) => {
                      toggleInlineStyle(e, "HIGHLIGHT");
                      setBgFontColorSelected(!bgFontColorSelected);
                    }}
                  >
                    <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                      <div className="ml-[-1px]">
                        <BgFontColorIcon />
                      </div>
                    </div>
                  </button>
                  <button
                    className={
                      fontOutlineColorSelected
                        ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                        : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                    }
                    onMouseDown={(e) => {
                      toggleInlineStyle(e, "OUTLINE");
                      setFontOutlineColorSelected(!fontOutlineColorSelected);
                    }}
                  >
                    <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                      <div>
                        <FontOutlineColor />
                      </div>
                    </div>
                  </button>
                  <button
                    className={
                      fontShadowSelected
                        ? "w-10 h-10 p-3 bg-[#444444] hover:bg-[#444444] transition duration-300 rounded-lg justify-center items-center flex"
                        : "w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] transition duration-300 rounded-lg justify-center items-center flex"
                    }
                    onMouseDown={(e) => {
                      toggleInlineStyle(e, "SHADOW");
                      setFontShadowSelected(!fontShadowSelected);
                    }}
                  >
                    <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                      <div className="ml-[-1px]">
                        <FontShadowIcon />
                      </div>
                    </div>
                  </button>
                </>
              )}

              <div className="inline-block h-[40px] min-h-[1px] w-[1px] m-1 self-stretch bg-[#444444] dark:bg-[#444444]/10"></div>

              {!showExpandedTools && (
                <button
                  className="w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] rounded-lg justify-center items-center flex"
                  onClick={() => setShowExpandedTools(true)}
                >
                  <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                    <div className="ml-[-1px]">
                      <ExpandIcon />
                    </div>
                  </div>
                </button>
              )}
              {showExpandedTools && (
                <button
                  className="w-10 h-10 p-3 bg-black hover:bg-[#2c2c2c] rounded-lg justify-center items-center flex"
                  onClick={() => setShowExpandedTools(false)}
                >
                  <div className="w-4 h-4 relative flex-col justify-start items-start flex">
                    <div className="ml-[-1px]">
                      <CollapseIcon />
                    </div>
                  </div>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ZapRichTextEditor;
