import React, { useState } from "react";

import ReactModal from "react-modal";
import { CloseIcon } from "../icons/CloseIcon";
import { AddIcon } from "../icons/AddIcon";
import { CollectionIcon } from "../icons/CollectionIcon";
import { CityVerseIcon } from "../icons/CityVerseIcon";
import { UnsplashIcon } from "../icons/UnsplashIcon";
import { AIIcon } from "../icons/AIIcon";
import { TrashIcon } from "../icons/TrashIcon";
import { Collection } from "./providers/collection/Collection";
import { CityVerse } from "./providers/cityverse/CityVerse";
import { Unsplash } from "./providers/unsplash/Unsplash";
import { AIStudio } from "./providers/aistudio/AIStudio";
import { Trash } from "./providers/trash/Trash";
import MediaUploadModal from "../upload/MediaUploadModal";

const MainModal = ({ title, isOpen, onClose = null, onFinish = null }) => {
  const sources = [
    {
      type: "divider",
    },
    {
      type: "collection",
      name: "Collection",
      icon: <CollectionIcon width={20} height={20} />,
      main: <Collection />,
    },
    {
      type: "divider",
    },
    {
      type: "cityverse",
      name: "Cityverse",
      icon: <CityVerseIcon width={20} height={20} />,
      main: <CityVerse />,
    },
    {
      type: "unsplash",
      name: "Unsplash",
      icon: <UnsplashIcon width={20} height={20} />,
      main: <Unsplash />,
    },
    {
      type: "ai-studio",
      name: "AI Studio",
      icon: <AIIcon width={20} height={20} />,
      main: <AIStudio />,
    },
    {
      type: "divider",
    },
    {
      type: "trash",
      name: "Trash",
      icon: <TrashIcon width={20} height={22} />,
      main: <Trash />,
    },
  ];
  // State for selected location
  const [selectedSource, setSelectedSource] = useState("local");
  const [modalState, setModalState] = useState({
    title: title ?? "Upload Media",
    isOpen: !!isOpen,
  });

  const [isFileModalOpen, setIsFileModalOpen] = useState(false);

  return (
    <div>
      <ReactModal
        isOpen={modalState.isOpen}
        ariaHideApp={false}
        overlayClassName={
          "bg-black bg-opacity-50 flex justify-center w-[600px]" +
          (isFileModalOpen ? " !hidden" : "")
        }
        className={
          "absolute inset-40 outline-none" + (isFileModalOpen ? " !hidden" : "")
        }
      >
        <div className="relative w-full bg-black justify-center text-white rounded-[20px] border-white">
          <header className="flex items-center justify-between py-5 pl-8 pr-4 border-b border-b-white">
            <div className="font-bold text-xl">{modalState.title}</div>
            <div className="flex items-center">
              <span
                onClick={onClose}
                role="presentation"
                className="inline-flex cursor-pointer"
              >
                <CloseIcon />
              </span>
            </div>
          </header>
          <main>
            <div className="flex w-full h-full overflow-hidden md:flex-row flex-col auto-rows-min">
              {/* Sidebar */}
              <div className="xl:max-w-[25%] lg:max-w-[30%] md:max-w-[40%] max-w-full w-full p-4 border-r border-r-white">
                <ul>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        setIsFileModalOpen(true);
                      }}
                      className="text-blue-500 align-middle flex py-3 px-6 my-4 font-medium w-full"
                    >
                      <>
                        <AddIcon width={20} height={20} />
                        <span className="leading-tight ml-2">Upload Media</span>
                      </>
                    </button>
                  </li>
                  {sources.map((item, index) => (
                    <li
                      key={index}
                      role="presentation"
                      className={
                        (item.type === selectedSource
                          ? "bg-[#6C00FA] rounded-lg "
                          : "") +
                        (item.type === "local" ? "mb-6 mt-3" : "") +
                        "max-h-[90px]"
                      }
                    >
                      {item.type !== "divider" ? (
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedSource(item.type);
                          }}
                          className="text-blue-500 align-middle flex py-3 px-6 my-4 font-medium w-full"
                        >
                          <>
                            {item.icon}
                            <span className="leading-tight ml-2">
                              {item.name}
                            </span>
                          </>
                        </button>
                      ) : (
                        <span className="h-[1px] w-full bg-[#A0A0A0] block">
                          {item.name}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Main Content */}
              <div className="xl:max-w-[75%] lg:max-w-[70%] md:max-w-[60%] md:border-t-0 max-w-full w-full flex-grow border-t border-t-white">
                {sources
                  .filter((item) => item.type === selectedSource)
                  .map((item, index) => (
                    <React.Fragment key={index}>{item.main}</React.Fragment>
                  ))}
              </div>
            </div>
          </main>
          <footer className="p-6 justify-end flex border-t border-t-white">
            <button
              onClick={onFinish}
              className="px-4 py-2 rounded-xl border-t-white border hover:bg-[#6C00FA] hover:border-[#6C00FA]"
            >
              Add To Post
            </button>
          </footer>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isFileModalOpen}
        ariaHideApp={false}
        overlayClassName="bg-black bg-opacity-50 flex justify-center w-[600px]"
        className="absolute inset-40 outline-none"
      >
        <MediaUploadModal
          onClose={() => {
            setIsFileModalOpen(false);
          }}
        />
      </ReactModal>
    </div>
  );
};

export default MainModal;
