export function ICloudIcon(props) {
  const { isBigVersion } = props;
  return (
    <svg
      width={isBigVersion ? "48" : "16"}
      height={isBigVersion ? "48" : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.881 4.0005C8.30319 4.00162 7.73608 4.15653 7.23794 4.44931C6.7398 4.7421 6.32857 5.16221 6.0465 5.6665C5.80378 5.54839 5.53743 5.48684 5.2675 5.4865C4.84422 5.48757 4.43509 5.63899 4.1131 5.91373C3.7911 6.18847 3.57718 6.56867 3.5095 6.9865C3.06252 7.17361 2.68078 7.4886 2.41222 7.89193C2.14366 8.29526 2.00025 8.76894 2 9.2535C2.00053 9.90549 2.25997 10.5306 2.72128 10.9913C3.1826 11.4521 3.80801 11.7108 4.46 11.7105C4.5632 11.7095 4.66624 11.702 4.7685 11.688H11.9785C13.131 11.552 13.999 10.559 14 9.3935V9.389C13.9996 8.85532 13.8134 8.33842 13.4733 7.92711C13.1333 7.51579 12.6606 7.23572 12.1365 7.135C12.1054 6.2926 11.7487 5.49511 11.1415 4.91038C10.5343 4.32566 9.72397 3.9998 8.881 4.0005Z"
        fill={isBigVersion ? "#AE70FF" : "#E0E0E0"}
      />
    </svg>
  );
}
