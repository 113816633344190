import { CityVerseIcon } from "../../../icons/CityVerseIcon";

export function CityVerse() {
  return (
    <div className="text-center w-full flex items-center justify-center flex-col h-full">
      <p>
        <CityVerseIcon color="#AE70FF" width={60} height={60} />
      </p>
      <div className="font-semibold">
        <p className="mt-4">Cityverse Assets Droping here soon!</p>
        <p className="text-[#AE70FF] underline mt-2">Hit me when they drop</p>
      </div>
    </div>
  );
}
