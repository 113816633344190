type ChevronIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
  direction: string;
};

export function ChevronIcon({
  addClassName = "",
  width = 24,
  height = 24,
  color = "#FFFFFF",
  direction = "up",
}: ChevronIconProps) {
  let aspectRatio = 1.71;
  let padding = 3;

  let svgHeight = Math.ceil((width - padding) / aspectRatio);
  let className =
    "inline-flex border rounded-full border-[" +
    color +
    "] items-center" +
    " " +
    addClassName;

  let svgClassName = "";
  if (direction === "down") {
    svgClassName += "rotate-180";
  }

  return (
    <span
      className={className}
      style={{
        width: width - padding + "px",
        height: height - padding + "px",
        padding: padding + "px",
      }}
    >
      <svg
        width={width - padding}
        className={svgClassName}
        height={svgHeight}
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 6L6 1L11 6" stroke={color} />
      </svg>
    </span>
  );
}
