export function QuoteIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96344 2C7.45826 2 7.70567 2.2123 7.70567 2.63691C7.70567 2.86852 7.50518 3.31725 7.1042 3.98311C6.71176 4.63932 6.31932 5.32449 5.92687 6.0386C5.53443 6.75271 5.33821 7.25935 5.33821 7.5585C5.33821 7.84801 5.40646 8.12304 5.54296 8.38359C5.67946 8.6345 5.8245 8.85645 5.97806 9.04946C6.41316 9.56092 6.63071 10.0676 6.63071 10.5694C6.63071 10.7334 6.60512 10.9071 6.55393 11.0905C6.33211 11.8721 5.92687 12.5525 5.33821 13.1315C4.75807 13.7105 4.0841 14 3.31627 14C2.55698 14 1.98111 13.7491 1.58867 13.2473C1.19622 12.7455 1 11.9783 1 10.9457C1 9.9035 1.35832 8.67793 2.07495 7.269C2.80012 5.85042 3.69165 4.62002 4.74954 3.5778C5.80743 2.52593 6.5454 2 6.96344 2ZM14.2578 2C14.7526 2 15 2.2123 15 2.63691C15 2.86852 14.7995 3.31725 14.3985 3.98311C14.0061 4.63932 13.6137 5.32449 13.2212 6.0386C12.8288 6.75271 12.6325 7.25935 12.6325 7.5585C12.6325 7.84801 12.7008 8.12304 12.8373 8.38359C12.9738 8.6345 13.1188 8.85645 13.2724 9.04946C13.7075 9.56092 13.925 10.0676 13.925 10.5694C13.925 10.7334 13.8995 10.9071 13.8483 11.0905C13.6264 11.8721 13.2212 12.5525 12.6325 13.1315C12.0524 13.7105 11.3784 14 10.6106 14C9.85131 14 9.27544 13.7491 8.883 13.2473C8.49055 12.7455 8.29433 11.9783 8.29433 10.9457C8.29433 9.9035 8.65265 8.67793 9.36929 7.269C10.0945 5.85042 10.986 4.62002 12.0439 3.5778C13.1018 2.52593 13.8397 2 14.2578 2Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
