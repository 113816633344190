export function CommentIcon() {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.51602 10.838H1V2.08984H17.6264V8.07546C17.6264 9.80207 16.8292 11.5287 15.4627 12.5647L8.51602 18.0898V10.838Z"
        stroke="#E0E0E0"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
}
