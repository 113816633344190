import { DragAndDropIcon } from "../../../icons/DragAndDropIcon";
import React, { useRef } from "react";
import { ComputerIcon } from "../../../icons/ComputerIcon";

export function Upload() {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {};

  return (
    <>
      <div className="py-10 px-10 sm:py-20 mt-10 mb-10 sm:px-20 max-w-[80%] w-full relative bg-[#00ffff]/10 rounded border border-[#00ffff]">
        <div className="text-center text-white text-base font-medium font-['Inter'] leading-normal">
          <div className="flex justify-center items-center mb-5">
            <ComputerIcon isBigVersion={true} />
          </div>
          Drag and drop your media files here <br />
          or
        </div>
        <div className="flex justify-center items-center">
          <button
            className="h-10 px-6 py-2 rounded-xl border-2 border-[#ad70ff] justify-center items-center gap-2.5 inline-flex mt-2"
            onClick={handleButtonClick}
          >
            <div className="text-[#e0e0e0] text-base font-medium font-['Inter'] leading-normal">
              Browse from Computer
            </div>
          </button>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }} // Hide the default input element
        onChange={handleFileChange}
      />
    </>
  );
}
