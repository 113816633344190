import { MultiPreviewIcon } from "../../../../icons/MultiPreview";

export function ZeroSelection() {
  return (
    <div className="w-full text-center">
      <div className="w-full mb-4">
        <MultiPreviewIcon width={150} height={150} />
      </div>
      <div className="w-full">0 items selected</div>
    </div>
  );
}
