import { ReactNode } from "react";
import { ChevronIcon } from "../../icons/ChevronIcon/ChevronIcon";
import { EventData, MediaItem } from "../../../utils/Types";

type AccordionProps = {
  title: string;
  children: ReactNode;
  isExpanded: boolean;
  onClick?(event: EventData<{}>): void;
};

export function Accordion({
  title,
  children,
  isExpanded = false,
  onClick = null,
}: AccordionProps) {
  let bodyClass = "text-sm overflow-hidden";
  if (!isExpanded) {
    bodyClass += " hidden";
  }

  return (
    <div className="border-b border-b-white pb-2 mb-2">
      <div className="flex justify-between items-center">
        <div className="text-lg">{title}</div>
        <div
          className="cursor-pointer"
          role={"presentation"}
          onClick={(e) => {
            const params: EventData<{}> = {
              event: e,
              data: {},
            };
            onClick(params);
          }}
        >
          <ChevronIcon
            width={28}
            height={28}
            direction={isExpanded ? "up" : "down"}
          />
        </div>
      </div>
      <div className={bodyClass}>{children}</div>
    </div>
  );
}
