export const Home = () => {
  return (
    <div className="text-white p-4 text-center">
      <div className="w-full mb-2">
        <a href="/home/media">Media Modal</a>
      </div>
      <div className="w-full mb-2">
        <a href="/home/zaps">Zap Composer</a>
      </div>
    </div>
  );
};
