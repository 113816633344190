import { DropdownItem, EventData } from "../../../../../utils/Types";

type DropdownProps = {
  onClick?(event: EventData<DropdownItem>): void;
  items?: Array<DropdownItem>;
};

export function Dropdown({ items, onClick = null }: DropdownProps) {
  return (
    <div className="absolute bg-black rounded-xl top-8 right-0 w-40 shadow-[0px_0px_6px_2px_rgba(255,255,255,_0.3)] overflow-hidden">
      {items.map((item) => (
        <div
          key={"dropdownItem-" + item.key}
          className="py-2 px-4 hover:bg-[#262626]"
          role="presentation"
          onClick={(e) => {
            const params: EventData<DropdownItem> = {
              event: e,
              data: item,
            };
            if (onClick) {
              onClick(params);
            }
          }}
        >
          {item.icon ? <div className="mr-2">{item.icon}</div> : ""}
          <div>{item.name}</div>
        </div>
      ))}
    </div>
  );
}
