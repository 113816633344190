const isDev = () => window.location.hostname.includes("localhost");

export async function fetchRequest({
  path,
  body,
  params,
  method,
  config = {},
  authenticated = false,
  service = "news-publisher",
}: {
  path: string;
  body?: any;
  method?: string;
  params?: string;
  config?: RequestInit;
  authenticated?: boolean;
  service?: string;
}) {
  const apiUrl = `https://${service}.ashybeach-de0b57bf.eastus.azurecontainerapps.io/api_v1`;
  const headers: Record<string, string> = {};
  const [tenant] = window.location.hostname.split(".")[0].split("-");
  const token = window.localStorage.getItem("token") || "";

  if (authenticated) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (body) {
    config.body = typeof body === "string" ? body : JSON.stringify(body);
  }

  const customConfig = {
    method: method ?? "GET",
    ...config,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-tenant-id": isDev() ? "stpete" : tenant,
      ...headers,
      ...config.headers,
    },
  };

  return fetch(`${apiUrl}/${path}${params ?? ""}`, customConfig);
}

export async function queryRequest({
  path,
  body,
  params,
  method,
  config,
  authenticated,
  service,
}: {
  path: string;
  body?: any;
  method?: string;
  params?: string;
  config?: RequestInit;
  authenticated?: boolean;
  service?: string;
}) {
  const response = await fetchRequest({
    path,
    body,
    params,
    method,
    config,
    authenticated,
    service,
  });

  const token = window.localStorage.getItem("token") || "";

  if (!response.ok) {
    // handle faulty responses
    const errorResponse = await response.json();
    if (response.status === 401 && token) {
      window.localStorage.removeItem("token");
      // redirect to login url
      window.location.href = "/auth/login";
    } else {
      throw new Error(errorResponse.message);
    }
  }

  return response.json();
}

export async function searchQueryRequest(type, request) {
  let path = "";
  let params = "";
  let query = request.text || "";
  let paginated = false;
  let key = "posts";

  switch (type) {
    case "author":
      path = "authors";
      params = "name=" + query;
      break;
    case "comments":
    case "comment":
      (path += "news/wp/comments"), (params += "");
      break;
    case "categories":
      path = "news/categories";
      params = "title=" + query;
      break;
    case "tags":
      path += "tags";
      params = "name=" + query;
      break;
    case "newsletterSpecs":
      path = "newsletterSpecs";
      params = "title=" + query;
      key = "items";
      paginated = true;
      break;
    default:
      path += "news/posts";
      params = "postType=" + type + "&title=" + query;
      paginated = true;
  }

  params = "?" + params + "&take=20&page=1";

  return fetchRequest({
    path: path,
    params: params,
    method: "GET",
    body: "",
    authenticated: true,
  })
    .then((response) => response.json())
    .then((items) => {
      return paginated ? items.data[key] : items.data;
    });
}
