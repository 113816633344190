type MagnifierIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function MagnifierIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: MagnifierIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.01127 9.01127L13.5 13.5M5.9 10.3C8.33005 10.3 10.3 8.33005 10.3 5.9C10.3 3.46995 8.33005 1.5 5.9 1.5C3.46995 1.5 1.5 3.46995 1.5 5.9C1.5 8.33005 3.46995 10.3 5.9 10.3Z"
          stroke={color}
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
