import { DropBoxIcon } from "../../../icons/DropBoxIcon";
import React from "react";

const handleButtonClick = () => {};

export function Facebook() {
  return (
    <div
      className={
        "w-[576px] h-[369px] relative bg-[#00000000] rounded border border-[#00ffff00]"
      }
    >
      <div className=" mt-32 text-center text-white text-base font-medium font-['Inter'] leading-normal">
        <div className="flex justify-center items-center mb-5">
          <DropBoxIcon isBigVersion={true}></DropBoxIcon>
        </div>
        Import photos and videos from your Facebook
        <br /> account
      </div>
      <div className="flex justify-center items-center">
        <button
          className="h-10 px-6 py-2 rounded-xl border-2 border-[#ad70ff] justify-center items-center gap-2.5 inline-flex mt-2"
          onClick={handleButtonClick}
        >
          <div className="text-[#e0e0e0] text-base font-medium font-['Inter'] leading-normal">
            Connect Facebook
          </div>
        </button>
      </div>
    </div>
  );
}
