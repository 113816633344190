export function MiniPinIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1818 5.09091C10.1818 8.27273 6.09091 11 6.09091 11C6.09091 11 2 8.27273 2 5.09091C2 4.00593 2.43101 2.96539 3.1982 2.1982C3.96539 1.43101 5.00593 1 6.09091 1C7.17589 1 8.21642 1.43101 8.98362 2.1982C9.75081 2.96539 10.1818 4.00593 10.1818 5.09091Z"
        stroke="#E0E0E0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
