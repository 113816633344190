export function FluentIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2.08984H8V8.08984H2V16.0898"
        stroke="#E0E0E0"
        stroke-width="2"
      />
      <rect x="1.5" y="1.58984" width="1" height="1" stroke="#E0E0E0" />
    </svg>
  );
}
