type OrderIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function OrderDateDescIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: OrderIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 12H12M3 16.5H12M18 6V18M18 18L21 15M18 18L15 15M3 3H21M3 21H21M3 7.5H12"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
