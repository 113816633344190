import { AIIcon } from "../../../icons/AIIcon";

export function AIStudio() {
  return (
    <div className="text-center w-full flex items-center justify-center flex-col h-full">
      <p>
        <AIIcon color="#AE70FF" width={60} height={60} />
      </p>
      <div className="font-semibold">
        <p className="mt-4">AI Studio launching soon!</p>
        <p className="text-[#AE70FF] underline mt-2">
          Notify me before take off
        </p>
      </div>
    </div>
  );
}
