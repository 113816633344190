import React, { useState, useRef } from "react";
import { DownIcon } from "./DownIcon";
import { EpicIcon } from "./EpicIcon";
import { ZapIcon } from "./ZapIcon";

function ZapContentSwitcher() {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState("computer");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    // Trigger the hidden file input click
    fileInputRef.current.click();
  };

  return (
    <div className="w-[800px] h-[116px] relative bg-black rounded-2xl border border-[#2c2c2c] mb-5">
      <div className="w-[800px] px-10 left-0 top-[26px] absolute justify-between items-center inline-flex">
        <div className="justify-start items-center gap-10 flex">
          <ZapIcon />
          <div className="h-16 w-[500px] flex-col justify-start items-start gap-1 inline-flex">
            <div className="self-stretch text-[#00ffff] text-base font-normal font-['Inter'] leading-normal">
              You are currently in Zap mode
            </div>
            <div className="self-stretch text-[#e0e0e0] text-sm font-normal font-['Inter'] leading-none">
              It seems like you have a lot to share. From here, you can go Epic!
              continue writing, and even add pictures.
            </div>
          </div>
        </div>
        <div className="p-3 rounded-[10px] border border-[#383838] justify-center items-center gap-2.5 flex">
          <div className="w-6 h-6 relative">
            <EpicIcon />
          </div>
          <div className="text-white text-sm font-normal font-['Inter'] leading-none">
            Go Epic
          </div>
        </div>
      </div>
      <div className="w-5 h-5 left-[768px] top-[12px] absolute rounded-[28px] border border-[#2c2c2c] justify-center items-center inline-flex">
        <DownIcon />
      </div>
    </div>
  );
}

export default ZapContentSwitcher;
