export function FontShadowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.87317 0.0251263C10.0526 -0.0466634 10.2563 0.0406321 10.3281 0.220106L14.7224 11.2058C15.3084 12.6708 14.5958 14.3335 13.1308 14.9195C11.6658 15.5056 10.0031 14.793 9.41705 13.3279L8.67051 11.4616H6.6804L5.93385 13.3279C5.34784 14.793 3.68514 15.5056 2.22011 14.9195C2.04063 14.8478 1.95334 14.6441 2.02513 14.4646C2.09692 14.2851 2.30061 14.1978 2.48008 14.2696C3.58616 14.712 4.84149 14.174 5.28392 13.068L6.11847 10.9816C6.17162 10.8487 6.30032 10.7616 6.44343 10.7616H8.90747C9.05058 10.7616 9.17928 10.8487 9.23243 10.9816L10.067 13.068C10.5094 14.174 11.7647 14.712 12.8708 14.2696C13.9769 13.8272 14.5149 12.5719 14.0725 11.4658L9.67819 0.48008C9.6064 0.300606 9.6937 0.096916 9.87317 0.0251263Z"
        fill="#E0E0E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.0173 1.01671C7.12467 0.748279 7.38465 0.572266 7.67375 0.572266C7.96286 0.572266 8.22283 0.748279 8.3302 1.01671L12.7245 12.0024C12.8695 12.3649 12.6932 12.7764 12.3306 12.9214C11.9681 13.0664 11.5566 12.8901 11.4116 12.5276L10.1999 9.49827C10.1543 9.38438 10.044 9.30969 9.92132 9.30969H5.42618C5.30351 9.30969 5.1932 9.38438 5.14764 9.49827L3.93593 12.5276C3.79091 12.8901 3.37944 13.0664 3.01689 12.9214C2.65435 12.7764 2.478 12.3649 2.62302 12.0024L7.0173 1.01671ZM7.95229 3.87936C7.90674 3.76546 7.79642 3.69078 7.67375 3.69078C7.55108 3.69078 7.44077 3.76546 7.39521 3.87936L5.95326 7.48423C5.91629 7.57666 5.92757 7.68141 5.98338 7.76384C6.03919 7.84627 6.13225 7.89565 6.2318 7.89565H9.1157C9.21525 7.89565 9.30831 7.84627 9.36412 7.76384C9.41993 7.68141 9.43121 7.57666 9.39424 7.48423L7.95229 3.87936Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
