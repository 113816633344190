type AIIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function AIIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: AIIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.33317 1.95898C10.2306 1.95898 10.9582 1.23144 10.9582 0.333984H11.7082C11.7082 1.23144 12.4357 1.95898 13.3332 1.95898V2.70898C12.4357 2.70898 11.7082 3.43652 11.7082 4.33398H10.9582C10.9582 3.43652 10.2306 2.70898 9.33317 2.70898V1.95898ZM0.666504 6.33398C2.87564 6.33398 4.6665 4.54312 4.6665 2.33398H5.99984C5.99984 4.54312 7.7907 6.33398 9.99984 6.33398V7.66732C7.7907 7.66732 5.99984 9.45819 5.99984 11.6673H4.6665C4.6665 9.45819 2.87564 7.66732 0.666504 7.66732V6.33398ZM3.25051 7.00065C4.12462 7.48572 4.84812 8.20918 5.33317 9.08332C5.81822 8.20918 6.54172 7.48572 7.41584 7.00065C6.54172 6.51558 5.81822 5.79212 5.33317 4.91799C4.84812 5.79212 4.12462 6.51558 3.25051 7.00065ZM11.4998 8.33398C11.4998 9.53058 10.5298 10.5007 9.33317 10.5007V11.5007C10.5298 11.5007 11.4998 12.4707 11.4998 13.6673H12.4998C12.4998 12.4707 13.4699 11.5007 14.6665 11.5007V10.5007C13.4699 10.5007 12.4998 9.53058 12.4998 8.33398H11.4998Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
