export function ZapIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4009 5.59725C11.3685 5.86155 11.4357 6.16759 11.5144 6.42725C12.1393 8.48603 12.762 10.5425 13.387 12.6013C13.5559 13.1577 13.7249 13.7512 13.5768 14.3123C13.4657 14.7273 13.1948 15.0751 12.9333 15.4136C11.9611 16.6725 11.0422 17.9685 10.1764 19.3016C10.0885 19.4361 9.99822 19.5844 10.0005 19.7444C10.0052 19.9832 10.2065 20.1641 10.3894 20.3148C12.9726 22.4153 15.5744 24.4926 18.1946 26.5467C18.5835 26.8528 19.1205 27.1681 19.5348 26.8968C19.9561 26.6209 19.8705 25.9857 19.7339 25.5011C19.2061 23.6209 18.6784 21.7429 18.1506 19.8627C17.9886 19.2854 17.8243 18.6918 17.8844 18.096C17.9747 17.1941 18.5511 16.4267 19.1089 15.7126C19.6205 15.0565 20.132 14.4004 20.6413 13.7443C20.8959 13.4197 21.1574 13.0766 21.2292 12.6685C21.3681 11.8826 20.5556 11.5209 20.0441 11.1082C19.2571 10.4753 18.4678 9.84001 17.6807 9.20707C16.1299 7.95974 14.579 6.71242 13.0282 5.46278C12.63 5.14051 12.056 4.81361 11.6509 5.1266C11.4982 5.24484 11.4264 5.40945 11.4032 5.59493L11.4009 5.59725Z"
        fill="#00FFFF"
      />
    </svg>
  );
}
