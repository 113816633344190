import React, { useState } from "react";
import { ComputerIcon } from "../icons/ComputerIcon";
import { DropBoxIcon } from "../icons/DropBoxIcon";
import { FacebookIcon } from "../icons/FacebookIcon";
import { GoogleDriveIcon } from "../icons/GoogleDriveIcon";
import { ICloudIcon } from "../icons/ICloudIcon";
import { InstagramIcon } from "../icons/InstagramIcon";
import { Facebook } from "./providers/facebook/Facebook";
import { Dropbox } from "./providers/dropbox/Dropbox";
import { Upload } from "./providers/upload/Upload";
import { GDrive } from "./providers/gdrive/GDrive";
import { Instagram } from "./providers/instagram/Instagram";
import { ICloud } from "./providers/icloud/ICloud";
import { CloseIcon } from "../icons/CloseIcon";

type MediaUploadModalProps = {
  onClose?(): void;
};

function MediaUploadModal({ onClose = null }: MediaUploadModalProps) {
  const sources = [
    {
      type: "computer",
      name: "Computer",
      icon: <ComputerIcon width={20} height={20} />,
      main: <Upload />,
    },
    {
      type: "divider",
    },
    {
      type: "dropbox",
      name: "DropBox",
      icon: <DropBoxIcon width={20} height={20} />,
      main: <Dropbox />,
    },
    {
      type: "facebook",
      name: "Facebook",
      icon: <FacebookIcon width={20} height={20} />,
      main: <Facebook />,
    },
    {
      type: "gdrive",
      name: "Google Drive",
      icon: <GoogleDriveIcon width={20} height={20} />,
      main: <GDrive />,
    },
    {
      type: "icloud",
      name: "iCloud",
      icon: <ICloudIcon width={20} height={20} />,
      main: <ICloud />,
    },
    {
      type: "instagram",
      name: "Instagram",
      icon: <InstagramIcon width={20} height={22} />,
      main: <Instagram />,
    },
  ];

  // State for selected location
  const [selectedSource, setSelectedSource] = useState("computer");

  return (
    <div className="relative w-full bg-black justify-center text-white rounded-[20px] border-white">
      <header className="flex items-center justify-between py-5 pl-8 pr-4 border-b border-b-white">
        <div className="font-bold text-xl">Add Media</div>
        <div className="flex items-center">
          <span
            role="presentation"
            className="inline-flex cursor-pointer"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </header>
      <main>
        <div className="flex w-full h-full overflow-hidden md:flex-row flex-col auto-rows-min">
          <div className="xl:max-w-[25%] lg:max-w-[30%] md:max-w-[40%] max-w-full w-full p-4 border-r border-r-white">
            <ul>
              {sources.map((item, index) => (
                <li
                  key={index}
                  role="presentation"
                  className={
                    (item.type === selectedSource
                      ? "bg-[#6C00FA] rounded-lg "
                      : "") +
                    (item.type === "local" ? "mb-6 mt-3" : "") +
                    "max-h-[90px]"
                  }
                >
                  {item.type !== "divider" ? (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedSource(item.type);
                      }}
                      className="text-blue-500 align-middle flex py-3 px-6 my-4 font-medium w-full"
                    >
                      <>
                        {item.icon}
                        <span className="leading-tight ml-2">{item.name}</span>
                      </>
                    </button>
                  ) : (
                    <span className="h-[1px] w-full bg-[#A0A0A0] block">
                      {item.name}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="xl:max-w-[75%] lg:max-w-[70%] md:max-w-[60%] md:border-t-0 max-w-full w-full flex-grow border-t border-t-white flex justify-center items-center">
            {sources
              .filter((item) => item.type === selectedSource)
              .map((item, index) => (
                <React.Fragment key={index}>{item.main}</React.Fragment>
              ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default MediaUploadModal;
