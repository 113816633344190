type EyeOpenIconProps = {
  addClassName?: string;
  width?: number;
  height?: number;
  color?: string;
};

export function EyeOpenIcon({
  addClassName = "inline-block text-white",
  width = 32,
  height = 32,
  color = "#FFFFFF",
}: EyeOpenIconProps) {
  return (
    <span className={addClassName}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.75 6.99805C0.75 6.99805 3.75 0.998047 9 0.998047C14.25 0.998047 17.25 6.99805 17.25 6.99805C17.25 6.99805 14.25 12.998 9 12.998C3.75 12.998 0.75 6.99805 0.75 6.99805Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.99836 9.24775C10.241 9.24775 11.2484 8.24039 11.2484 6.99775C11.2484 5.7551 10.241 4.74775 8.99836 4.74775C7.75572 4.74775 6.74836 5.7551 6.74836 6.99775C6.74836 8.24039 7.75572 9.24775 8.99836 9.24775Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}
