export function ClockIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99854 14C11.3122 14 13.9985 11.3137 13.9985 8C13.9985 4.68629 11.3122 2 7.99854 2C4.68483 2 1.99854 4.68629 1.99854 8C1.99854 11.3137 4.68483 14 7.99854 14Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99854 4.40234V8.00234L10.3985 9.20234"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
